<template>


  <div class="mt-2">
    <div class="card public-profile border-0 rounded shadow">
      <div class="card-body">
        <div class="d-flex mb-4  align-items-center">
          <div class="col-3">
            <!--<h4 class=" title mb-0 text-primary mr-12" @click="toJob(job.vacancy_id)"
                @mouseover="hover = true"  @mouseleave="hover = false"  :class="{ active: hover }">
              {{job.vacancy_name}}
            </h4> -->


              <h4 class=" title mb-0 text-primary mr-12" @click="$router.push({path:'/vacancycheck/'+job.job_id})"
                  @mouseover="hover = true"  @mouseleave="hover = false"  :class="{ active: hover }">
                {{job.job_title}}
                 </h4>






            <ul class="list-inline mb-0 mt-2">
              <!--<li class="list-inline-item mr-2"><small class="text-muted h6 mr-2">Web Developer -</small></li>-->
              <li class="list-inline-item">
                  <map-pin-icon class="fea icon-sm" v-if="job.job_city"></map-pin-icon>  <small class="text-muted h6 mr-2"> {{job.job_city}}</small>
              </li>
              <!--
              <li class="list-inline-item">
                <small class="text-muted h6 mr-2">- возраст: от {{job.age_from}} до {{job.age_to}} лет</small>
              </li>
              <li class="list-inline-item">
                <small class="text-muted h6 mr-2">- опыт: от {{job.experience_from}} до {{job.experience_to}} лет</small>
              </li>
              <li class="list-inline-item">
                <small class="text-muted h6 mr-2">- з/п: от {{job.salary_from}}k до {{job.salary_to}}k ₽</small>
              </li>
                -->
            </ul>

          </div>
          <div class="col-6 ">
            <div class="list-inline d-flex justify-content-between">

                <div class="list-inline-item">
                  <h6 class="text-center text-primary"> {{job.funnel_stage_name}} </h6>
                  <h6 class="text-center text-primary" @click="toJob(job.job_id)"
                      @mouseover="hover = true"  @mouseleave="hover = false"  :class="{ active: hover }"> Отобранные кандидаты: {{job.numberOfResumes}} </h6>
                  <h6 class="text-center text-primary"><a @click="toJobApplications(job.job_id)" class="text-center text-primary"
                         @mouseover="hover = true"  @mouseleave="hover = false"  :class="{ active: hover }"> Отклики: {{job.numberOfResumesApplications}} </a> </h6>
                  <h6 v-if="job.is_public">Ссылка на вакансию: <a :href="joburl(job.job_id)" class="text-primary">{{joburl(job.job_id)}}</a> </h6>
                  <!--<p class="text-muted text-center">{{ funnel_stages.funnel_stage_name }}</p>-->



                </div>



            </div>

           <!-- <table><tr>
              <td v-for="funnelItem in job.funnel" v-if="funnelItem.number_of_resumes>=0">
                <h5 class="text-center">{{ funnelItem.number_of_resumes }}</h5>
                <p class="text-muted text-center">{{ funnelItem.funnel_stage_name }}</p>

              </td>
            </tr></table> -->

          </div>




          <div>
          <div class="d-flex  justify-content-between">
            <div>
            <span class="badge badge-pill badge-primary ml-12" v-b-tooltip.hover.top title="Нажмите, чтобы перевести в Архивные"
                  @mouseover="hover = true"  @mouseleave="hover = false"  :class="{ active: hover }"
                  v-if="job.status==='Активная'" @click="updateVacancy(job.job_id,job.status,'status')">
                  Активная
            </span>
              <span class="badge badge-pill badge-warning ml-12"
                    v-b-tooltip.hover.top title="Нажмите, чтобы перевести в Активные"
                    @mouseover="hover = true"  @mouseleave="hover = false"  :class="{ active: hover }"
                    v-else @click="updateVacancy(job.job_id,job.status,'status')">
                  Архивная
            </span>
            </div>
            <!--
            <div>
              <a href="#" class="btn btn-icon btn-pills btn-outline-success"><eye-icon class="fea icon-sm"></eye-icon></a>
            </div>
            -->
            <div >
              <a @click="$router.push({path:'/job-edit/'+job.job_id})"
                 v-b-tooltip.hover.top title="Редактировать"
                 class="btn btn-icon btn-pills btn-outline-info"><edit-icon class="fea icon-sm"></edit-icon></a>
            </div>

            <div >
              <a class="btn btn-icon btn-pills btn-outline-danger"
                 v-b-tooltip.hover.top title="Удалить"
                 @click="deleteVacancy(job.job_id)">
                <trash-icon class="fea icon-sm"></trash-icon></a>
            </div>

          </div>
            <div >
              <small class="text-muted">Создана: {{job.date_from}}</small>
            </div>

          </div>

        </div>

      <!--<div class="row align-items-end align-content-between"> -->

        <!-- <div class="text-lg-left text-center ml-4"> -->

        <!--
        <div class="list-inline text-md-left text-center ml-4 align-content-between">
          <div class="list-inline-item">
          <h4 class=" title mb-0 text-primary mr-12" @click="toJob(job.vacancy_id)"
             @mouseover="hover = true"  @mouseleave="hover = false"  :class="{ active: hover }">
            {{job.vacancy_name}}
          </h4>
          </div>
          <div class="list-inline-item ml-12">
            <span class="badge badge-pill badge-primary ml-12"
                  @mouseover="hover = true"  @mouseleave="hover = false"  :class="{ active: hover }"
                  v-if="job.status==='Активная'" @click="updateVacancy(job.vacancy_id,job.status,'status')">
                  Активная
            </span>
            <span class="badge badge-pill badge-warning ml-12"
                  @mouseover="hover = true"  @mouseleave="hover = false"  :class="{ active: hover }"
                  v-else @click="updateVacancy(job.vacancy_id,job.status,'status')">
                  Архивная
            </span>
          </div>

          <div class="list-inline-item ml-12">
            <a href="#" class="btn btn-icon btn-pills btn-outline-success"><eye-icon class="fea icon-sm"></eye-icon></a>
          </div>
          <div class="list-inline-item ml-12">
            <a href="#" class="btn btn-icon btn-pills btn-outline-info"><edit-icon class="fea icon-sm"></edit-icon></a>
          </div>

          <div class="list-inline-item ml-12">
            <a href="#" class="btn btn-icon btn-pills btn-outline-danger"
               @click="updateVacancy(job.vacancy_id,'','delete')">
              <trash-icon class="fea icon-sm"></trash-icon></a>
          </div>
          <div class="list-inline-item ml-12">
           <small class="text-muted">Созадана:<br> {{job.date_from}}</small>
          </div>
      </div>

        -->

       <!-- </div> -->


        <!--
        <div class="row align-items-end">
          <div class="text-lg-left text-center ml-4">

          <ul class="list-inline mb-0 mt-2">

            <li class="list-inline-item">
          <small class="text-muted h6 mr-2"> {{job.location_city}}</small>
            </li>
            <li class="list-inline-item">
              <small class="text-muted h6 mr-2">- возраст: от {{job.age_from}} до {{job.age_to}} лет</small>
            </li>
            <li class="list-inline-item">
              <small class="text-muted h6 mr-2">- опыт: от {{job.experience_from}} до {{job.experience_to}} лет</small>
            </li>
            <li class="list-inline-item">
              <small class="text-muted h6 mr-2">- з/п: от {{job.salary_from}}k до {{job.salary_to}}k ₽</small>
            </li>

          </ul>

        </div>
        </div>
        -->
        <!--
          <div class="row align-items-end" >
            <div class="col-md-7 text-md-left text-center  mt-sm-0 ml-2">
              <ul class="list-inline mb-0 ">
                <li class="list-inline-item mr-2">
                  <a href="#" class="btn btn-pills btn-info btn-md mr-2"> Java</a>
                </li>
                <li class="list-inline-item mr-2">
                  <a href="#" class="btn btn-pills btn-info btn-md mr-2"> Spring</a>
                </li>

              </ul>
            </div>
          </div>
          -->
        <!--
      <div class="row align-items-end">
        <div class="col-lg-12 ml-4 mt-4 pt-2" v-for="job in resume.jobs">
          <div class="media">
            <div class="company-logo text-muted h6 mr-3 text-center">
              {{ job.start_date }} - {{ job.end_date_str }}
              <br/>
              {{job.experience_years}} лет
            </div>
            <div class="media-body">
              <h5 class="title mb-0">{{ job.position }}</h5>
              <small class="text-muted company-university"
              >{{ job.company_name }} - {{ job.location_city}}<span v-if="job.location_country!=='Россия'">, {{job.location_country}}</span> </small
              >
            </div>
          </div>
        </div>

      </div>
    -->

    </div>
    <!--
    <input
        class="searchbtn btn btn-primary btn-block"
        value="Показать резюме"
        @click="searchCandidates"
    />
      -->
  </div>

  </div>

</template>

<script>

import store from "@/store/store";

import {
  TrashIcon,
  MapPinIcon,
  EditIcon,

} from "vue-feather-icons";

export default {
  name: "VacancyHightlight",
  components: {
    TrashIcon,
    MapPinIcon,
    EditIcon,
  },

  props: {
    job: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
/*
    vacancyNames: {
      type: Array,
      required: true,
    },

    index: {
      type: Number,
      required: true,
    },
*/
  },

  data() {
    return {
      hover: false,

      saveResume: {
        user_id: 1,
        resume_id:1,
        save_search_name: '',
        save_search_id: 1,
      },

      jobFunnels: [],


      jobDescription : {
        user_id: 0 ,
        job_id: 0 ,
        job_title: '',
        job_city: '',
        job_type: '',
        job_company: '',
        job_salary_from: 0,
        job_salary_to: 0,
        job_salary_currency: '',
        job_description: '',
        job_status: 'Активная',
        createFlag: 0,
        funnel_stages: [],

      },

      in_funnel_sql : {
        id: 0 ,
        user_id: 1,

        vacancy_id: 1,
        vacancy_name: '',
        funnel_stage_order_id: 1,
        funnel_stage_name: '',

        editFlag: false,
      },
      funnel_stages: [],


      input_update : {
        user_id: 1,
        vacancy_id: 1,
        vacancy_name: '',
        status: '',
        actionType: '',
      },

      funnel_stage_id: 0,


    }
  },

  mounted() {
    //this.getCandidates(this.$route.params.vacancy_id);
   // this.getCandidatesFast();
   // this.getStages();
  },

  methods: {

    toJobApplications(x) {
      let route = this.$router.resolve({path: '/responses/' +x });
      window.open(route.href,"_self");
    },

    toJob(x) {
      //let route = this.$router.resolve({path: '/resumes-list/job/' +x +"/page/1"});
      let route = this.$router.resolve({path: '/resumes-list/job/' +x });
      //window.open(route.href, '_blank');
      window.open(route.href,"_self");
    },

    joburl(x) {
      let route = 'https://skillrank.ru/vacancy-public/' +x ;
      return route
    },



    deleteVacancy(x) {

      console.log('inside create new job')
      this.jobDescription.user_id = store.state.user_id;
      this.jobDescription.job_id = x;
      this.jobDescription.createFlag=2
      this.axios({
        method: 'post',
        url: '' + this.$hostname + '/prod/job-description/create-update?access_token=' + store.state.token,
        data: this.jobDescription,
      }).then((res) => {
        if (res.data.status==='ok') {

         // this.$vs.notify({
         //   time: 8000,
         //   title: 'Успех',
         //   text: 'Вакансия удалена',
         //   color: 'success'
         // });

          let route = this.$router.resolve({path: '/job-list'});
          window.open(route.href, '_self');
        //  this.$router.push({path:'/job-list'});
        }
      });
    },

    getAllVacancies(){

      this.input_save_vacancy.user_id = store.state.user_id;

      var url = '' + this.$hostname + '/prod/job-description/list?access_token=' + store.state.token;
      console.log('inside get all jobs ' + this.input_save_vacancy + '  ' + url)

      this.axios({
        method: 'post',
        url: url,
        data: this.input_save_vacancy,
      }).then((res) => {

        this.jobFunnelsClone = res.data.funnel_arr;
        this.jobFunnels = res.data.funnel_arr;
        console.log('inside get all vacancies in /email');
        console.log(this.jobFunnels);

        this.allVacancies = res.data.list;
        this.activeVacancies = this.allVacancies.filter(x => x.status === 'Активная');
        this.archiveVacancies = this.allVacancies.filter(x => x.status === 'Архивная');

        console.log(this.allVacancies);
        console.log(this.activeVacancies);
        /*
          if (this.allVacancies.length) {
            this.savedSearch = this.allVacancies[0];
          }
        */
      });

    },

    getStages() {

      console.log('inside getStages')
      this.input_get_resumes.user_id = store.state.user_id;
      this.input_get_resumes.vacancy_id = this.$route.params.vacancy_id;
      //this.input_get_resumes.page = this.$route.params.page;

      this.axios({
        method: 'post',
        url: '' + this.$hostname + '/prod/vacancy-funnel/get-stage?access_token=' + store.state.token,
        data: this.input_get_resumes,
      }).then((res_in) => {
        if (res_in.data.status === 'ok') {
          this.funnel_stages = res_in.data.funnel_stages;
          this.vacancy_name = res_in.data.vacancy_name;
          console.log('this.funnel_stages:');
          console.log(res_in.data);
        }
      });




    },







    updateVacancy(vacancy_id, status, type) {


      this.input_update.user_id = store.state.user_id;
      this.input_update.vacancy_id = vacancy_id;
      this.input_update.actionType = type;



        if (status === 'Активная') {
          this.input_update.status = 2
        } else {
          this.input_update.status = 1
        }

        this.axios({
          method: 'post',
          url: '' + this.$hostname + '/prod/vacancy/update?access_token=' + store.state.token,
          data: this.input_update,
        }).then((res) => {
          if (res.data.status === 'Done!') {
            /*
            this.$vs.notify({
              time: 4000,
              title: 'Успех',
              text: 'Данные сохранены',
              color: 'success'
            });
            */
            this.$emit('index',this.index);
          } else {

          //  this.$vs.notify({
          //    time: 4000,
          //    title: 'Неудача',
          //    text: 'Данные не сохранены',
          //    color: 'danger'
          //  });
          }
        });






    }
  }
}
</script>

